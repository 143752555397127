.footer {
  position: relative;
  text-align: center;
  background: $footer-background;
  color: $white;
  padding-top: $grid-gutter-width * 3;
  overflow-x: hidden;


  .footer-top {
    padding: $footer-top-padding;
    background: $footer-top-background;
    overflow-x: hidden;

    &::before {
      content: "";
      background: url("/images/transparent-top-bg.svg") no-repeat;
      background-size: cover;
      background-position: center -25px;
      width: 101%;
      height: 120px;
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 10;

      @include media-breakpoint-down(xs) {
        background: url("/images/mobile-transparent-top-bg.svg") no-repeat top / contain;
    }
    }

    .footer_text {
      margin-bottom: $grid-gutter-width * 1.667;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }

      h3 {
        font-family: $headings-font-family;
        font-size: $h2-font-size;
        color: $white;
      }
      p {
        max-width: 500px;
        margin: 0 auto;
      }
    }
    .footer_logolink {
      max-width: 500px;
      margin: ($grid-gutter-width / 1.5) auto (ceil($grid-gutter-width * 3));
     
      @include media-breakpoint-up(sm) {
        margin: ($grid-gutter-width / 1.5) auto (ceil($grid-gutter-width * 1.35));
      }

      .list {
        align-items: center;
        justify-content: space-evenly;

        .list-item {
          @extend .btn-primary;
          padding: 6px 20px !important;

          .link {
            display: flex;
            align-items: center;
            text-decoration: none;
          }
          &:hover {
            border-color: $white !important;
          }
          @include media-breakpoint-down(sm) {
            margin-bottom: $grid-gutter-width / 6;
          }
          i {
            margin-right: $grid-gutter-width / 3;
            font-size: $h3-font-size;
            font-weight: bold;
          }
        }
      }
    }
    .page_block {
      &.logo {
        margin-bottom: $grid-gutter-width / 1.5;
      }
    }
  }
  .footer-middle {
    margin-bottom: 50px;

    .container-six-columns {
      .container-holder {
        .column {
          @include media-breakpoint-down(sm) {
            @include make-col(6);
          }
          .footer_list {
            .list {
              .list-item {
                font-size: $font-size-base_sm;

                &:not(:last-child) {
                  margin-bottom: $grid-gutter-width / 6;
                }
                .link {
                  &:hover,
                  &:active {
                    color: $base-1;
                  }
                }
              }
            }
          }
        }
      }
    }
    .vocabulary_term_list {
      li {
        font-size: 0;
        a {
          &:hover,
          &:active {
           i {
            color: $base-1;
           }
          }
          i {
            font-size: $h4-font-size;
            color: $white;
            margin-right: $grid-gutter-width / 3;
          }
        }
      }
    }
  }

  .footer-socket {
    padding: ($spacer * 0.5) 0 ($spacer);
    background: $footer-socket-background;

    .column {
      &.navbar {
        justify-content: flex-start;

        @include media-breakpoint-up(sm) {
          justify-content: center;
        }
        .footer_link {
          .list {
            text-align: left;

            .list-item {
              margin: 0 ($grid-gutter-width) ($grid-gutter-width/6) 0;
              font-size: $font-size-base_sm;

              @include media-breakpoint-up(sm) {
                margin: 0 ($grid-gutter-width) 0 0;
              }
              .link {
                color: $tertiary;

                &:hover,
                &:active {
                  color: $secondary;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer_list {
    text-align: left;
    h5 {
      color: $base-1;
      font-family: $font-seaweed-script;
      font-size: $h4-font-size;
    }
  }
  a.link {
    color: $white;
  }
}
