header {
    .header-main {
        background: $base-2 !important;

        .container-navbar {
            .container-holder {
                .navbar {
                    position: relative;
                    padding: 8px;
                    @include media-breakpoint-up(xl) {
                        padding: 6px 36px;
                    }
                    .page_block.logo {
                        width: auto !important;
                        background: $white;
                        padding: 13px 22px 0;
                        max-width: 100%;
                        position: absolute;
                        top: 0;
                        box-shadow: 10px 10px 20px rgba($tertiary, 0.3);

                        @media screen and(max-width:375px) {
                            padding: 13px 13px 0;
                        }
                        @include media-breakpoint-down(lg) {
                            order: 2;
                            margin-left: 45px;
                        }
                        @include media-breakpoint-up(lg) {
                            padding: 23px 30px 0;
                        }

                        &::after {
                            content: "";
                            background: url("/images/bottom-path.svg") no-repeat bottom / cover;
                            width: 100%;
                            height: 100px;
                            position: absolute;
                            bottom: -65px;
                            left: 0;
                            background-size: 100%;

                            @media screen and(max-width:375px) {
                                bottom: -45px;
                            }
                            @include media-breakpoint-up(lg) {
                                bottom: -97px;
                            }
                        }
                        a {
                            z-index: 12;
                            position: relative;

                            img {
                                max-width: 110px;

                                @media screen and(max-width:375px) {
                                    max-width: 80px;
                                }
                                @include media-breakpoint-up(lg) {
                                    max-width: 169px;
                                }
                            }
                        }
                    }
                    &-collapse {
                        @include media-breakpoint-down(lg) {
                            order: 5;
                        }
                        @include media-breakpoint-up(xl) {
                            padding: 12px 0;
                        }
                        .navbar-nav {
                            @include media-breakpoint-down(lg) {
                                margin-top: $grid-gutter-width * 3.334;
                                padding: 0 ($grid-gutter-width / 2);
                                align-items: normal;
                            }
                            .nav-item {
                                font-family: $font-martel;

                                @include media-breakpoint-up(xl) {
                                    margin: 0 ($grid-gutter-width / 2) 0 0;
                                }

                                .nav-link {
                                    color: $black;

                                    &:hover,
                                    &:active {
                                        color: $secondary;
                                    }
                                }
                                .dropdown-toggle {
                                    position: relative;

                                    &::after {
                                        content: "\f078";
                                        position: absolute;
                                        right: -5px;
                                        display: block;
                                        font-family: $font-awesome;
                                        font-weight: 900;
                                        transform: translateY(-50%);
                                        top: 50%;
                                        font-size: $font-size-base_xxs;
                                        border: none !important;
                                    }
                                    @include media-breakpoint-down(lg) {
                                        &[aria-expanded="true"] {
                                            &::after {
                                                content: "\f068";
                                            }
                                        }
                                        &::after {
                                            margin-right: 3px;
                                            content: "\F067";
                                            font-family: $font-awesome;
                                            font-size: $font-size-base_xs;
                                            font-weight: 700;
                                            border: none;
                                        }
                                    }
                                }
                                .dropdown-menu {
                                    left: -10%;
                                    border: 1px solid $base-3;
                                    border-radius: 6px;
                                    box-shadow: 0px 3px 6px $box-shadow;

                                    .nav-item {
                                        margin: 0;

                                        @include media-breakpoint-up(xl) {
                                            margin-right: 0;
                                        }
                                        .nav-link {
                                            color: $base-4;
                                            padding: 8px 15px;
                                            font-size: $font-size-base_sm !important;
                                            font-weight: 700;
                                            text-transform: capitalize;

                                            &:hover,
                                            &:active {
                                                color: $secondary;
                                            }
                                        }
                                    }
                                    @include media-breakpoint-up(xl) {
                                        &:before,
                                        &:after {
                                            content: "";
                                            display: block;
                                            height: 0;
                                            left: 50%;
                                            position: absolute;
                                            width: 0;
                                        }
                                        &:before {
                                            border-left: 8px solid transparent;
                                            border-right: 8px solid transparent;
                                            border-bottom: 6px solid $base-1;
                                            margin-left: -5px;
                                            top: -6px;
                                            z-index: 4;
                                        }
                                        &:after {
                                            border-left: 6px solid transparent;
                                            border-right: 6px solid transparent;
                                            border-bottom: 5px solid $white;
                                            margin-left: -3px;
                                            top: -5px;
                                            z-index: 5;
                                        }
                                    }
                                    @include media-breakpoint-down(lg) {
                                        position: static !important;
                                        transform: none !important;
                                        min-width: 100%;
                                        background: $base-2;
                                        border: none;
                                        box-shadow: none;
                                        padding: 0;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                    &-nav {
                        align-items: center;

                        &.socials {
                            flex-direction: row;
                            @include media-breakpoint-down(lg) {
                                order: 4;
                            }
                            .nav-item {
                                @media screen and(max-width:375px) {
                                    display: none;
                                    &:last-child {
                                        display: block;
                                    }
                                }
                                .nav-link {
                                    color: $tertiary;

                                    &:hover {
                                        opacity: 0.7;
                                    }
                                    i {
                                        font-size: $font-size-base * 1.5;

                                        @include media-breakpoint-down(xs) {
                                            font-size: $font-size-base * 1.125;
                                        }
                                    }
                                }
                                &:not(:last-child) {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                    .search-book-button {
                        margin-bottom: 0;
                        @include media-breakpoint-down(lg) {
                            order: 3;
                            flex-grow: 1;
                            justify-content: flex-end;
                        }
                        li {
                            a {
                                @media screen and(max-width:375px) {
                                    font-size: $font-size-base / 1.6;
                                    padding: 7px 12px !important;
                                }
                                @include media-breakpoint-down(sm) {
                                    font-size: $font-size-base_xxs !important;
                                    padding: 7px 15px !important;
                                }
                                @extend .btn-tertiary;
                                text-transform: uppercase;
                            }
                        }
                    }
                    &-toggler {
                        margin: 0;
                        @include media-breakpoint-down(lg) {
                            order: 1;
                        }
                        &-icon {
                            i {
                                &::before {
                                    content: url("/images/Hamburger_menu.svg") !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .header {
        &.sticky {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }
    }
}
