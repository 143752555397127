$body-padding: 0;
$body-sticky-padding: 0;

/* ==========================================================================
   footer
   ========================================================================== */
   $footer-background: $tertiary !default;
   $footer-top-background: $tertiary !default;
   $footer-socket-background: $white !default;

   $base-1: #C1DF9C;
   $base-2: #DFEBE9;
   $base-3: #E1E1E1;;
   $base-4: #333333;
   $base-5: #353720;
   $btn-border-color: #FFFFFF40;
   $box-shadow: rgba($black, 0.16);
   $card-box-shadow: #0B39544D;