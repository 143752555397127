.mini-sab {
    background: $base-2 !important;
    
    .container-default {
        .container-holder {
            .mini_search_book {
                display: flex;
                width: 100%;
                flex-direction: column;

                @include media-breakpoint-up(lg) {
                    flex-direction: row;
                }

                h1 {
                    font-size: $font-size-base * 2.25;
                    font-family: $font-seaweed-script;
                    color: $tertiary;
                }
            }
        }
    }
}
