.eyecatcher {
    .container-default {
        .container-holder {
            .owl-carousel {
                .owl-stage-outer {
                    position: relative;

                    &::after {
                        content: "";
                        background: url("/images/banner-bottom-bg-mobile.svg") no-repeat bottom/cover;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        z-index: 1;
                        background-size: 100%;

                        @include media-breakpoint-up(md) {
                            background: url("/images/banner-bottom-xl.svg") no-repeat bottom/cover;
                            background-size: 100%;
                        }
                    }
                }
                .owl-dots {
                    bottom: 75px;
                }
            }
        }
    }

    &.large {
        position: relative;
        min-height: 50vh;

        @media (min-width: 1500px) {
            min-height: 70vh;
        }

        .eyecatcher-container {
            .owl-carousel {
                min-height: 50vh;

                @media (min-width: 1500px) {
                    min-height: 70vh;
                }
                .item {
                    position: relative;

                    min-height: 70vh;
                    background-position: 0 0;
                    @media (min-width: 1500px) and (max-width: 1700px) {
                        background-position: center 75px;
                    }
                    @media (min-width: 1500px) {
                        min-height: 70vh;
                        background-size: 100%;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        background: linear-gradient(180deg, transparent, $black);
                        bottom: 0;
                        left: 0;
                        height: 120px;
                        width: 100%;
                        opacity: 0.7;
                    }
                    .owl-container {
                        display: flex;
                        justify-content: center;
                        max-width: 640px;
                        position: absolute;
                        bottom: 85px;
                        margin: auto;
                        left: 0;
                        right: 0;

                        .owl-caption {
                            .owl-title {
                                text-align: center;
                                font-size: $font-size-base * 2.25;
                                @media screen and(max-width:375px) {
                                    font-size: $font-size-base * 1.8;
                                }
                                @include media-breakpoint-up(lg) {
                                    font-size: $h1-font-size;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.spacer {
    &.large {
        .container-default {
            position: relative;

            &::before {
                content: "";
                background: url("/images/transparent-top-bg.svg") no-repeat top / contain;
                width: 101%;
                height: 120px;
                position: absolute;
                top: 0;
                left: -1px;
                z-index: 10;

                @include media-breakpoint-down(xs) {
                    background: url("/images/mobile-transparent-top-bg.svg") no-repeat top / contain;
                }
                @include media-breakpoint-up(lg) {
                    background-size: cover;
                    background-position: center -25px;
                }
            }
            &::after {
                content: "";
                background: url("/images/path.svg") no-repeat bottom/cover;
                width: 101%;
                height: 120px;
                position: absolute;
                bottom: -1px;
                left: -1px;
                z-index: 1;
                background-size: 100%;

                @include media-breakpoint-down(xs) {
                    background: url("/images/mobile-bottom-bg.svg") no-repeat bottom / contain;
                }
            }
            .container-holder {
                .owl-carousel {
                    .item {
                        position: relative;

                        .owl-container {
                            display: flex;
                            justify-content: center;
                            max-width: 640px;
                            position: absolute;
                            bottom: 85px;
                            margin: auto;
                            left: 0;
                            right: 0;

                            .owl-caption {
                                .owl-title {
                                    text-align: center;
                                    font-size: $font-size-base * 2.25;
                                    @include media-breakpoint-up(lg) {
                                        font-size: $h1-font-size;
                                    }
                                }
                            }
                        }
                    }
                    .owl-dots {
                        bottom: 75px;
                    }
                }
            }
        }
    }
}
