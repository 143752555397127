.main {
    position: relative;
    overflow-x: hidden;

    &::before {
        content: "";
        background: url("/images/top-path.svg") no-repeat top/cover;
        width: 101%;
        height: 145px;
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 1;
        background-size: 100%;
        pointer-events: none;

        @include media-breakpoint-down(sm) {
            background: url("/images/mobile-top-bg.svg") no-repeat top;
            background-size: 100%;
        }
    }
    .intro.content {
        background: $tertiary;
        padding: 140px 0 60px !important;

        .container-default {
            .container-holder {
                text-align: center;
                position: relative;

                h1 {
                    color: $white;
                }
                h4 {
                    font-size: $h2-font-size;
                    color: $base-1;
                    font-family: $font-seaweed-script;

                    @include media-breakpoint-up(sm) {
                        font-size: $font-size-base * 2.5;
                    }
                }
                &::after {
                    content: "";
                    background: url("/images/vintage-border.svg") no-repeat bottom/contain;
                    width: 100%;
                    height: 32px;
                    position: absolute;
                    bottom: -45px;
                    left: 0;
                    z-index: 1;
                }
            }
        }
        .container-two-columns {
            margin-top: 80px;
            position: relative;

            &::after {
                content: "";
                background: url("/images/repeat-grid.svg") no-repeat bottom/contain;
                background-size: inherit;
                width: 80%;
                height: 32px;
                position: absolute;
                bottom: -45px;
                left: 10%;
                z-index: 1;

                @include media-breakpoint-up(sm) {
                    width: 100%;
                    left: 0;
                }
            }
            .container-holder {
                .column {
                    z-index: 1;

                    p {
                        color: $white;
                        line-height: 1.8;
                    }
                    .btn-block {
                        padding-top: 5px;

                        .btn-primary {
                            &:first-of-type {
                                margin-right: 10px;

                                @include media-breakpoint-down(xs) {
                                    margin-bottom: 10px;
                                }
                            }
                            &:hover,
                            &:active {
                                border-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
    .home-blocks {
        background: $tertiary;
        padding: 60px 0;
        position: relative;

        @include media-breakpoint-up(sm) {
            padding: 60px 0 0;
        }

        &::after {
            content: "";
            background: url("/images/transparent-bottom-path.svg") no-repeat bottom/cover;
            width: 101%;
            height: 100%;
            position: absolute;
            bottom: -1px;
            left: -1px;
            z-index: 1;
            background-size: 100%;

            @include media-breakpoint-down(xs) {
                background: url("/images/mobile-transparent-bottom-path.svg") no-repeat bottom/contain;
            }
        }
        .container-default {
            .container-holder {
                h1 {
                    color: $white;
                    text-align: center;
                    margin-bottom: 50px;
                }
            }
        }
    }
}
.content.facilities {
    padding: 60px 0 0;

    @include media-breakpoint-up(sm) {
        padding: 120px 0 0;
    }

    .container-default {
        .container-holder {
            .grid {
                .item {
                    @include media-breakpoint-up(lg) {
                        @include make-col(4);
                    }
                    .card {
                        border: none;
                        box-shadow: none;

                        &-image {
                            font-size: 0;

                            &::after {
                                content: none;
                            }
                        }
                        &-body {
                            @include media-breakpoint-up(sm) {
                                padding: 5px 15px 15px;
                            }

                            .card-caption {
                                .card-title {
                                    color: $tertiary;
                                    font-size: $font-size-base * 2.25;
                                    font-family: $font-seaweed-script;
                                    text-align: center;
                                }
                                .card-description {
                                    min-height: 100%;
                                    max-height: 100%;
                                    font-size: $font-size-base * 1.125;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.slider-section.bg-dark {
    background: $base-2 !important;
    padding: 20px 0;
    position: relative;

    &::after {
        content: "";
        background: url("/images/top-path.svg") no-repeat bottom/cover;
        width: 100%;
        height: 120px;
        position: absolute;
        bottom: -120px;
        left: 0;
        z-index: 1;

        @include media-breakpoint-down(xs) {
            background: url("/images/mobile-top-bg.svg") no-repeat top / contain;
        }
    }

    .container-default.info {
        .container-holder {
            text-align: center;
            max-width: 500px;
            margin: auto;
            position: relative;

            &::after {
                content: "";
                background: url("/images/black-border.svg") no-repeat bottom/contain;
                width: 100%;
                height: 32px;
                position: absolute;
                bottom: -45px;
                left: 0;
                z-index: 1;
            }
            h1 {
                color: $secondary;
                font-size: $font-martel;
            }
            h4 {
                color: $tertiary;
                font-size: $h2-font-size;
                font-family: $font-seaweed-script;

                @include media-breakpoint-up(sm) {
                    font-size: $font-size-base * 2.5;
                }
            }
        }
    }
    .container-default.card-block {
        padding-top: 100px;
    }
}
.inspiration-blocks {
    padding-top: 180px !important;

    .container-two-columns {
        position: relative;

        &::before {
            content: "";
            background: url("/images/repeat-grid-blue.svg") no-repeat center;
            background-size: inherit;
            width: 80%;
            height: 32px;
            position: absolute;
            top: -75px;
            left: 10%;
            z-index: 1;

            @include media-breakpoint-up(sm) {
                width: 100%;
                left: 0;
                background-position: bottom/cover;
            }
        }
        .container-holder {
            .column {
                h1 {
                    font-size: $font-size-base * 1.75 !important;

                    @include media-breakpoint-up(sm) {
                        font-size: $h2-font-size !important;
                    }
                }
                p {
                    line-height: 1.8;

                    a {
                        @extend .btn-primary;
                        text-decoration: none;
                        margin-top: 12px;
                        position: relative;
                        z-index: 1;
                    }
                }
                &.two {
                    @include media-breakpoint-down(sm) {
                        margin-top: $grid-gutter-width / 1.5;
                    }
                }
            }
        }
    }
}
.content.info {
    margin-top: 50px;
  @include media-breakpoint-up(xl) {
    margin-top: 80px;
  }
}