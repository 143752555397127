p {
    line-height: 1.8 !important;
}
h1 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 2.25 !important;
	}
}
h2 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-base * 1.375;
	}
}

body {
	font-size: 16px !important;
}  