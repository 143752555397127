/* bootstrap > theme-colors
   ========================================================================== */
   $primary:    #0062AD !default;
   $secondary:  #71A92E !default;
   $tertiary:   #0B3954 !default;
   
   $base:       #333333;
   $body-color: $base !default;
   
   /* bootstrap > font
       ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Martel:wght@400;700&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Seaweed+Script&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=M+PLUS+2&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap');

   $font-martel:  'Martel', sans-serif;
   $font-MPLUS-2:  'M PLUS 2', sans-serif;
   $font-seaweed-script: 'Seaweed Script', cursive;
   $font-open-sans: 'Open Sans', sans-serif;
   $font-awesome: "Font Awesome 5 Pro";
   
   $font-family-base: $font-MPLUS-2 !default;
   
   $font-weight-regular: 400;
   $font-weight-base: 500;
   
   $font-size-base: 1rem !default; //16px
   $font-size-base_sm: 0.875rem !default; //14px
   $font-size-base_xs: 0.813rem !default; //13px
   $font-size-base_xxs: 0.75rem !default; //12px
   
   $h1-font-size: $font-size-base * 3 !default; //48px
   $h2-font-size: $font-size-base * 1.875 !default; //30px
   $h3-font-size: $font-size-base * 1.25 !default; //20px
   $h4-font-size: $font-size-base * 1.5!default; //24px
   $h5-font-size: $font-size-base !default;
   $h6-font-size: $font-size-base_xs !default;
   
   /* bootstrap > typography
      ========================================================================== */
   $headings-font-family: $font-martel !default;
   $headings-font-weight: 400;
   
   body.default {
      overflow-x: hidden;
   }
   
 