//MINI SAB

//label 
   .mini-sab .tommy-zeb-wrapper .tommy-zeb-left {
        color: black !important;
        background: transparent;
            label, .tommy-zeb-accommodation-person-category-label {
                font-family: $font-martel !important;
                font-size: 12px !important;
                font-weight: 600;
                font-size: 12px;
                height: 18px;
                color: black !important;
            }
    }

//widget    
    .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, 
    .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types{
        border: none;
    }


//categories
     tommy-search-and-book tommy-person-category .tommy-zeb-accommodation-person-category {
         clear: both;
     } 

	 .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-period .tommy-zeb-period, 
     .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category {
		display: flex;
		flex-flow: row nowrap;
        .tommy-zeb-date-begin, 
        .tommy-zeb-date-end {
            color: #000;
            background-color: white;
            width: 180px;
            height: 56px;
            border-radius: 4px;
            padding: 2px 2px 20px 8px;
            margin-right: 20px;
            margin-bottom: 0px;
            .tommy-zeb-date-begin-label, .tommy-zeb-date-end-label{
                height: 28px;
            }
          
        }
            @media screen and(max-width: 767px){
            margin-bottom: 25px;
		}
	}

//input 
    .tommy-zeb-wrapper .tommy-zeb-left select, 
    .tommy-zeb-wrapper .tommy-zeb-left input,     
    .tommy-zeb-accommodation-person-category-choice {
        margin:0;
        padding: 0;
        font-family:  $font-MPLUS-2 !important;
        font-size: 13px;
    }

//mini-sab
    .tommy-zeb-wrapper-mini h2.ng-binding{
        display: none;
    }
    .mini-sab .tommy-zeb-left .ng-scope{
        align-items: center !important;
    }
    .mini-sab .tommy-zeb-person-categories{
        align-items: center;
        display: flex;
        flex-direction: row;
        border-right: 1px solid black !important;
        margin-bottom: 50px;
        margin-top: 10px;
        @media screen and(max-width: 767px){
            border-right: none !important;
        }
       
    }
    .tommy-zeb-wrapper.tommy-zeb-wrapper-mini tommy-person-category-mini {
        .tommy-zeb-person-categories-collapse {
            .tommy-zeb-travelcompany-total{
                height: 35px !important;
                margin-top: 0px !important;
            }
            button.tommy-zeb-btn{
                margin: 0px !important;
                align-items: center;
            } 
        }
            .tommy-zeb-person-category-list{
                background-color: white !important;
                z-index: 2 !important;
                .tommy-zeb-accommodation-person-category-label{
                    font-family: $font-MPLUS-2 !important;
                    font-weight: 400;
                }
                .tommy-zeb-range{
                    background: #CD5D67 !important;
                    color: white;
                }
            }
            .tommy-zeb-accommodation-person-category-choice {
                text-align: center;
            }
    }
    

//Button 'zoeken'
	.tommy-zeb-wrapper-mini button.tommy-zeb-btn{
		@extend .btn-tertiary;
		background-color: #CD5D67 !important;
		border: 1px solid #CD5D67 !important;
		font-family: $font-martel !important;
		padding:  7px 10px;
        margin-top: 10px !important;  
	}
    .tommy-zeb-wrapper-mini button.tommy-zeb-btn:hover{
        background-color: white !important;
        border: 1px solid #CD5D67 !important;
        color: #CD5D67 !important;
    }

    .tommy-zeb-btn .tommy-zeb-update{
        margin: 0px !important;
        align-items: center;
        margin-left: 20px !important;
    }
 
    .tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .tommy-zeb-wrapper-mini .tommy-zeb-date, .tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types, .tommy-zeb-wrapper-mini .ng-isolate-scope{
        @media screen and(max-width: 767px){
            float: none !important;
        }
    }




//SAB ZOEK & BOEK PAGE
    .content.overview .tommy-zeb-wrapper .tommy-zeb-left {
        font-family: $font-MPLUS-2 !important;
        font-weight: 400;
        background: #0B3954;
    h1{
        font-size: 1.875rem !important;
        text-align: left !important;
        }       
    h2{
        font-size: 1rem !important;
        color: pink;
        }
    }
    .tommy-zeb-person-categories h2{
        color: pink;
    }
    .tommy-zeb-wrapper tommy-search-and-book tommy-period .tommy-zeb-period {
        flex-direction: column;
    }
    .tommy-zeb-wrapper tommy-search-and-book tommy-period .tommy-zeb-period .tommy-zeb-date-begin,
    .tommy-zeb-wrapper tommy-search-and-book tommy-period .tommy-zeb-period .tommy-zeb-date-end {
        width: 100%;
    }


    .content.overview {
        .tommy-zeb-wrapper .tommy-zeb-accommodation-category select, .tommy-zeb-wrapper .tommy-zeb-accommodation-objects select, .tommy-zeb-wrapper .tommy-zeb-accommodation-types select, .tommy-zeb-wrapper .tommy-zeb-date input{
            padding: 6px;
        }
    }




//RESPONSIVENESS mini-sab
@media screen and(max-width: 767px){
    .mini-sab .tommy-zeb-person-categories{
        justify-content: center !important;
        margin-bottom: 20px !important;
    }
    .mini-sab .tommy-zeb-left.ng-scope{
        display: flex !important;
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        align-content: center !important;
    }
    .tommy-zeb-period{
        justify-content: center !important;
    }
    .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-person-categories, .tommy-zeb-wrapper.tommy-zeb-wrapper-mini .tommy-zeb-accommodation-types{
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
    .page_block.mini_search_book{
        h1{
            align-items: center;
            align-content: center;
            display: flex !important;
            justify-content: center !important;
        }
    }
    .tommy-zeb-period{
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        width: 300px !important;
        align-content: center !important;
       // align-items: center !important;
        .tommy-zeb-date-begin, .tommy-zeb-date-end{
            margin-right: 0px  !important ;
            width: 95% !important;
            height: 60px !important;
        }
        .tommy-zeb-date-begin{
            margin-right: 5px !important;
        }
    }
   
  
}

@media screen and(max-width: 767px){
    .tommy-zeb-btn.tommy-zeb-update{
        margin: 0 !important;
        display: flex !important;
        justify-content: center !important;
        width: 300px !important;
    }}

@media screen and  (min-width: 991px) and (max-width: 1150px){
    .tommy-zeb-btn.tommy-zeb-update{
        position: absolute;
        top: 80px;
        right: 30px;
    }
    .mini_search_book{
        margin-bottom: 50px;
    }
}

@media screen and  (min-width: 768px) and (max-width: 991px){
    .tommy-zeb-btn.tommy-zeb-update{
        position: absolute;
        top: 80px;
        right: 10px;
    }
    .mini_search_book{
        margin-bottom: 50px;
    }
}

@media (min-width: 768px) {

    body.default .content ol:not(.carousel-indicators) li, body.default .content ul:not(.vocabulary_term_list) li {
        font-size: 13px !important;
    }

    .tbs-navbar-nav {
        margin: -0.5px -31px !important; 
    }

    h2.tbs-totaal {
        margin-top: 15px !important;
    }

    span.tbs-accommodatie.tbs-translate {
        font-size: 19px !important;
    }

    .tbs-row.tbs_kassabon_overzicht {
        margin-top: 14px !important;
    }

    .tbs-btn-info {
        background-color: #dfebe9;
        border-color: #dfebe9;
    }

    li.tbs-active {
        background-color: #dfebe9;
    } 

    .tbs-navbar-default .tbs-navbar-nav > li > a {
        color: #777;
        font-size: 13px !important;
    } 
    
}


