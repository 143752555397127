.main {
    .content.overview {
        padding: 100px 0 70px;

        @include media-breakpoint-up(sm) {
            padding: 140px 0 80px;
        }
        .container {
            h1 {
                text-align: center;

                &:first-child {
                    color: $tertiary;
                    font-size: $font-size-base * 2.5;
                    font-family: $font-seaweed-script;
                }
                &:nth-child(2) {
                    color: $secondary;
                    font-size: $h1-font-size;
                    font-family: $font-martel;
                    margin-bottom: $grid-gutter-width * 3;
                    position: relative;

                    &::after {
                        content: "";
                        background: url("/images/black-border.svg") no-repeat;
                        background-size: contain;
                        background-position: center 0;
                        position: absolute;
                        bottom: -45px;
                        left: 0;
                        right: 0;
                        max-width: 445px;
                        height: 32px;
                        margin: 0 auto;
                    }
                }
            }
            .info {
                .btn-back {
                    font-size: $font-size-base * 1.125;
                    color: $secondary;
                    font-family: $font-martel;
                    font-weight: 700;
                    text-decoration: none;
                    position: relative;
                    z-index: 1;

                    &:hover {
                        color: $tertiary;
                    }
                    &::before {
                        content: "\f104";
                        margin-right: $grid-gutter-width / 6;
                    }
                }
                h1 {
                    color: $secondary;
                    font-size: $h2-font-size;
                    font-family: $font-martel;
                    margin-bottom: $grid-gutter-width * 1.667 !important;
                    position: relative;
                    text-align: left !important;

                    @include media-breakpoint-up(xl) {
                        font-size: $h1-font-size;
                    }

                    &::after {
                        content: "";
                        background: url("/images/black-border.svg") no-repeat;
                        background-size: contain !important;
                        background-position: center 0;
                        position: absolute;
                        bottom: (-($spacer * 1.875)) !important;
                        left: 0;
                        right: 0;
                        max-width: 329px !important;
                        height: 24px;
                        margin: 0 !important;
                    }
                }
            }
            .info,
            .info-extra {
                width: 100%;
                @include media-breakpoint-up(md) {
                    @include make-col(6);
                }
            }
        }
    }
}

.content.detail {
    padding: 120px 0 40px;

    @include media-breakpoint-up(sm) {
        padding: 140px 0 60px;
    }
    .info {
        .btn-back {
            font-size: $font-size-base * 1.125;
            color: $secondary;
            font-family: $font-martel;
            font-weight: 700;
            text-decoration: none;
            z-index: 1;
            position: relative;

            &:hover {
                color: $tertiary;
            }
            &::before {
                content: "\f104";
                margin-right: $grid-gutter-width / 6;
            }
        }
        .extra {
            padding: 5px 0;
            font-size: $font-size-base_xxs;
            font-weight: 600;
            color: $tertiary;
        }
        h3 {
            color: $secondary;
        }
        p {
            line-height: 2;

            // a {
            //     @extend .btn-primary;
            //     margin-top: $grid-gutter-width / 3;
            //     position: relative;
            //     z-index: 1;
            // }
        }
        .accommodation-properties {
            list-style: none !important;
            padding: 0;

            li {
                padding-left: 0;

                span {
                    color: $tertiary;

                    i {
                        font-weight: 600;
                    }
                }
                &::before {
                    content: none;
                }
            }
        }
        ul {
            list-style-type: none;
            padding-left: $grid-gutter-width / 3;
            margin-top: $grid-gutter-width / 1.5;

            li {
                position: relative;
                padding-left: $grid-gutter-width / 1.5;
                margin-bottom: $grid-gutter-width / 3;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    content: "\F111";
                    transform: translateY(-50%);
                    font-size: $font-size-base / 2;
                    font-family: $font-awesome;
                    font-weight: 600 !important;
                    color: $base-5;
                }
            }
        }
        h1 {
            color: $secondary;
            font-size: $h2-font-size;
            font-family: $font-martel;
            margin-bottom: $grid-gutter-width * 1.667 !important;
            position: relative;
            text-align: left !important;

            @include media-breakpoint-up(xl) {
                font-size: $h1-font-size;
            }

            &::after {
                content: "";
                background: url("/images/black-border.svg") no-repeat;
                background-size: contain !important;
                background-position: center 0;
                position: absolute;
                bottom: (-($spacer * 1.875)) !important;
                left: 0;
                right: 0;
                max-width: 329px !important;
                height: 24px;
                margin: 0 !important;
            }
        }
    }
    .info, .info-extra {
        width: 100%;

        @include media-breakpoint-up(md) {
            @include make-col(6);
        }
    }
    .accommodation_detail {
        padding-bottom: 0 !important;
    }
}

&.contact {
    .main {
        .content.contact {
            padding: 120px 0 80px;
            .container-default {
                .container-holder {
                    h1 {
                        color: $secondary;
                        font-size: $h1-font-size;
                        font-family: $font-martel;
                        margin: $grid-gutter-width 0;
                        position: relative;
                        @include media-breakpoint-up(md) {
                            margin: 0 0 ($grid-gutter-width * 2) 0;
                        }

                        &::after {
                            content: "";
                            background: url("/images/black-border.svg") no-repeat;
                            background-size: cover;
                            background-position: center 0;
                            position: absolute;
                            bottom: -($grid-gutter-width);
                            left: 0;
                            right: 0;
                            max-width: 329px;
                            height: 24px;
                        }
                    }
                }
            }
            .container-two-columns {
                .container-holder {
                    h1 {
                        font-size: $h2-font-size;
                        & + p {
                            font-size: $h3-font-size;
                            font-family: $font-martel;
                            color: $secondary;
                        }
                    }

                    .column {
                        h3 {
                            &:nth-child(6) {
                                color: $secondary;
                                margin-top: $grid-gutter-width * 2;
                            }
                            &:nth-child(9) {
                                margin-top: $grid-gutter-width * 2;
                            }
                        }
                        p {
                            &:nth-child(4) {
                                display: flex;
                                flex-direction: column;
                                font-weight: 700;
                                margin-top: ($grid-gutter-width / 2);
                                a {
                                    color: $base;
                                }
                            }
                        }
                    }
                }
            }

            .page_block {
                &.form {
                    box-shadow: 10px 10px 20px $card-box-shadow;
                    padding: ($spacer * 4.125) ($spacer * 2);
                    position: relative;
                    z-index: 1;

                    .form-group {
                        label {
                            font-weight: 700;
                            font-family: $font-MPLUS-2;
                        }
                        .form-check {
                            margin: ($grid-gutter-width / 1.5) 0;

                            .form-check-input {
                                margin-top: 0.4rem;
                            }
                            .form-check-label {
                                font-weight: $font-weight-regular;
                            }
                        }
                    }
                }
            }
        }
    }
}

&.accommodation_search_book {
    .main {
        .content.overview {
            .container-default {
                .container-holder {
                    .page_block.search_book {
                        display: flex !important;
                        width: 100%;

                        .tommy-zeb-wrapper {
                            .tommy-zeb-result-details {
                                padding: 15px 15px 15px 30px !important;
                                @media screen and  (min-width: 480px) and (max-width: 767px){
                                    padding-top: 80% !important;
                                }
                            }

                            .tommy-zeb-result-label {
                                color: $base-4;
                                font-size: $h2-font-size;
                                font-family: $font-martel !important;
                                margin-bottom: $grid-gutter-width / 6;
                                font-weight: 400;
                            }
                            .tommy-zeb-result-description {
                                .tommy-zeb-result-text {
                                    div {
                                        ul {
                                            padding-left: 15px;

                                            li {
                                                font-family: $font-MPLUS-2 !important;
                                                line-height: 1.8;
                                            }
                                        }
                                    }
                                }
                            }
                            .tommy-zeb-result {
                                display: flex !important;
                                padding: 0 !important;
                                background-color: $white !important;
                                box-shadow: 10px 10px 20px $card-box-shadow;

                                @include media-breakpoint-down(md) {
                                    flex-wrap: wrap;
                                }

                                .tommy-zeb-result-image {
                                    min-width: 100%;

                                    @include media-breakpoint-up(lg) {
                                        min-width: auto;
                                    }
                                    @include media-breakpoint-up(md) {
                                        min-width: 200px;
                                    }

                                    img {
                                        width: 100%;
                                        @include media-breakpoint-up(lg) {
                                            max-width: 380px;
                                            height: 100%;
                                            border-radius: 4px 0 0 4px !important;
                                        }
                                    }
                                }
                            }
                            .tommy-zeb-result-price {
                                font-family: $font-martel !important;
                                color: $tertiary;
                            }
                            .tommy-zeb-btn {
                                @extend .btn-primary;
                                position: relative;
                                z-index: 1;
                                background-color: #0B3954;
                            }
                            .tommy-zeb-result-actions {
                                margin-top: $grid-gutter-width / 1.5;
                            }
                            .tommy-zeb-result-image {
                                margin: 0 !important;
                                border: 10px solid white;
                            }
                            .tommy-zeb-left {
                                h1 {
                                    font-family: $font-MPLUS-2 !important;
                                    color: $white;
                                }
                                .tommy-zeb-person-categories,
                                .tommy-zeb-accommodation-types,
                                .tommy-zeb-date,
                                .tommy-zeb-characteristics {
                                    h2 {
                                        font-family: $font-MPLUS-2 !important;
                                        color: $white;
                                        margin-top: 15px !important;

                                        @include media-breakpoint-up(sm) {
                                            font-size: $font-size-base * 1.2 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
