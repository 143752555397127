.home-blocks {
    .masonry.card-columns {
        z-index: 11;
        position: relative;
        @include media-breakpoint-down(xs) {
            display: flex;
            flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
            column-count: 3;
            break-inside: avoid;
        }
        .item {
            &:first-of-type,
            &:nth-child(4) {
                .card {
                    background: transparent !important;
                    border: none;

                    @include media-breakpoint-up(sm) {
                        padding: 100px 0 0;
                    }

                    &-image {
                        display: none;
                    }
                    &-body {
                        background: transparent;
                        padding: 0;

                        .card-title {
                            font-size: $font-size-base * 1.875 !important;

                            @include media-breakpoint-up(sm) {
                                font-size: $font-size-base * 2.25 !important;
                            }
                        }
                        .card-title,
                        .card-description-content {
                            color: $white;
                            text-align: left !important;
                        }
                    }
                }
            }
            &:nth-child(4) {
                @include media-breakpoint-down(xs) {
                    order: 5;
                }
                .card {
                    margin-top: 30px;
                    padding: 0;
                    width: 100%;
                    box-shadow: none;
                }
                .card-caption {
                    display: flex;
                    flex-direction: column;
                    margin: auto;
                    max-width: 267px;
                    .card-title {
                        order: 2;
                        @extend .btn-primary;
                        font-size: $font-size-base_sm !important;
                        width: auto;
                    }
                    .card-description {
                        order: 1;
                        margin-bottom: $grid-gutter-width;

                        &-content {
                            text-align: center !important;
                        }
                    }
                }
            }
            &:last-of-type {
                @include media-breakpoint-up(lg) {
                    padding-top: 180px;
                }
            }
            .card {
                display: inline-flex;
                flex-direction: column;
                border-radius: 5px;
                padding: 10px;
                text-decoration: none;
                box-shadow: 10px 10px 10px #0b39544d;

                &-image {
                    order: 2;

                    &::before {
                        content: "";
                        background: url("/images/image-top-path.svg") no-repeat top/contain;
                        background-size: 100%;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        pointer-events: none;
                    }
                    .card-img {
                        height: 500px;
                        object-fit: cover;
                    }
                }
                &-body {
                    order: 1;
                    padding: 40px 22px 20px;

                    @include media-breakpoint-up(sm) {
                        padding: 40px 22px 120px;
                    }
                    .card-caption {
                        .card-title {
                            font-size: $h2-font-size;
                            @include media-breakpoint-down(xs) {
                                word-break: break-all;
                            }
                        }
                        .card-description {
                            &-content {
                                font-size: $font-size-base;
                                line-height: 1.8;
                                text-align: center;
                            }
                            .list {
                                display: flex;
                                flex-wrap: wrap;

                                .list-item {
                                    color: $tertiary;

                                    &:first-of-type {
                                        margin-right: 10px;
                                    }
                                    .icon {
                                        i {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.slider-section {
    .masonry.card-columns {
        z-index: 11;
        position: relative;

        @include media-breakpoint-up(lg) {
            column-count: 3;
            break-inside: avoid;
        }
        .item {
            &:first-of-type {
                .card {
                    background: transparent !important;
                    box-shadow: none;
                    border: none;
                    margin-bottom: 0 !important;

                    @include media-breakpoint-up(sm) {
                        padding: 100px 0 0;
                        margin-bottom: 30px !important;
                    }
                    &-image {
                        display: none;
                    }
                    &-body {
                        background: transparent;
                        padding: 0;

                        .card-title {
                            font-size: $font-size-base * 1.875 !important;
                            text-align: left !important;

                            @include media-breakpoint-up(sm) {
                                font-size: $font-size-base * 2.25 !important;
                            }
                        }
                        .card-title,
                        .card-description-content {
                            color: $base-4;
                        }
                    }
                }
            }
            &:nth-child(6) {
                @include media-breakpoint-up(lg) {
                    padding-top: 100px;
                }
            }
            .card {
                display: inline-flex;
                flex-direction: column;
                border-radius: 5px;
                padding: 10px;
                text-decoration: none;
                box-shadow: 10px 10px 10px #0b39544d;

                &-image {
                    &::after {
                        content: "";
                        background: url("/images/image-bottom-path.svg") no-repeat bottom/contain;
                        background-size: 100%;
                        width: 101%;
                        height: 100%;
                        position: absolute;
                        bottom: -1px;
                        left: -1px;
                        z-index: 1;
                    }
                    .card-img {
                        height: 500px;
                        object-fit: cover;
                    }
                }
                &-body {
                    padding: 20px 22px 30px;
                    text-align: center;

                    .card-caption {
                        .card-title-link {
                            &:hover {
                                color: $secondary;
                            }
                        }
                        .card-title {
                            font-size: $h2-font-size;
                            text-align: center;
                        }
                        .card-description {
                            &-content {
                                font-size: $font-size-base;
                                line-height: 1.8;
                                text-align: center;
                            }
                            .list {
                                display: flex;
                                flex-wrap: wrap;

                                .list-item {
                                    &:first-of-type {
                                        margin-right: 10px;
                                    }
                                    .icon {
                                        i {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .card-buttons {
                        .card-btn {
                            background: transparent;
                            color: $secondary;
                            font-size: $font-size-base * 1.125 !important;
                            border: none;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}
.page_block {
    &.grid {
        position: relative;
        z-index: 1;
        .item {
            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }
            .card {
                box-shadow: 10px 10px 20px $card-box-shadow;
                border-radius: $grid-gutter-width / 6;
                border-color: $white;
                padding: $grid-gutter-width / 3;

                &-image {
                    position: relative;
                    padding: 0;
                    background: $white;

                    &::after {
                        content: "";
                        background: url("/images/card-thumb-img-bg.svg") no-repeat;
                        background-size: 100%;
                        background-position: center 0;
                        position: absolute;
                        bottom: -15px;
                        left: -2px;
                        width: 101%;
                        height: 50px;

                        @include media-breakpoint-up(xl) {
                            bottom: 0;
                        }
                    }
                }
                &-body {
                    padding: $grid-gutter-width / 3;

                    .card-caption {
                        display: flex;
                        flex-direction: column;
                        margin-top: $grid-gutter-width / 2;

                        .card-title-link {
                            order: 2;
                            .card-title {
                                font-family: $font-martel;
                                font-size: $font-size-base * 1.5;
                                color: $base;

                                @include media-breakpoint-up(sm) {
                                    font-size: $h2-font-size;
                                }
                            }
                        }
                        .card-subtitle {
                            font-family: $font-seaweed-script;
                            font-size: $h4-font-size;
                            color: $tertiary;
                            order: 1;
                        }
                        .card-prices {
                            order: 1;
                            .card-price-message {
                                font-family: $font-seaweed-script;
                                font-size: $h4-font-size;
                                color: $tertiary;
                                font-weight: $font-weight-regular;
                            }
                        }
                        .card-description {
                            margin: ($grid-gutter-width / 1.5) 0;
                            order: 3;
                            line-height: 1.8;
                            min-height: 100%;
                            max-height: 100%;

                            &::after {
                                content: none;
                            }

                            .list {
                                display: flex;
                                flex-wrap: wrap;

                                &-item {
                                    color: $tertiary;

                                    .icon {
                                        i {
                                            font-weight: 600;
                                        }
                                    }
                                    &:first-of-type {
                                        margin-right: $grid-gutter-width / 3;
                                    }
                                }
                            }
                        }
                    }
                }
                .card-buttons {
                    margin: ($grid-gutter-width / 3) 0;
                    position: relative;
                    z-index: 1;

                    .card-btn {
                        @extend .btn-primary;
                    }
                }
            }
        }
    }
}
