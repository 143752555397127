.btn {
    display: inline-flex;
    align-items: center;
    padding: 5px 20px;
    font-size: $font-size-base_sm;
    font-family: $font-martel;
    font-weight: 700;
    border-radius: 18px;
    color: $white;
    text-decoration: none;
    transition: 0.3s;
    box-shadow: 0px 3px 6px $box-shadow;

    &-primary {
        @extend .btn;
        color: $white;
        background: $primary;
        border-color: rgba($white, 0.25);
        padding: 7px 30px;

        &:hover,
        &:active {
            color: $white;
            background: $tertiary;
            border: $border-width solid $white;
        }
    }
    &-outline-primary {
        @extend .btn;
        color: $primary;
        background: $white;
        border: $border-width solid $primary;
        padding: 7px 30px;
       
        &:hover,
        &:active {
            color: $white;
            background: $primary;
         }
    }
    &-secondary {
        @extend .btn;
        color: $secondary;
        background: $white;
        border-color: $secondary;
        padding: 7px 30px;

        &:hover,
        &:active {
            color: $tertiary;
            background: $white;
            border: $border-width solid $tertiary;
        }
    }
    &-tertiary {
        @extend .btn;
        color: $white;
        background: $tertiary;
        border: $border-width solid $tertiary;
        padding: 7px 30px;
       
        &:hover,
        &:active {
            color: $tertiary;
            background: $white;
         }
    }
    &-outline-secondary {
        @extend .btn;
        color: $white;
        background: $secondary;
        border-color: $secondary;
       
        &:hover,
        &:active {
            color: $secondary;
            background: $white;
        }
    }
}